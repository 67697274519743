.block-column {
  height: 100%;
}

.block-label {
  width: 27px;
}

.alt-button {
  /* display: none !important; */
}

.button-visability {
  /* display: none !important; */
}