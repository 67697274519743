@import url(https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

pre {
  white-space: pre-wrap;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* #root, #root>div {
  height: 100%;
} */
.block-column {
  height: 100%;
}

.block-label {
  width: 27px;
}

.alt-button {
  /* display: none !important; */
}

.button-visability {
  /* display: none !important; */
}
